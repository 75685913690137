import LoadMyAccountInterface from './LoadMyAccountInterface'
import { injectable } from 'tsyringe';
import { API, Auth } from 'aws-amplify'
import * as Sentry from '@sentry/browser';
import UserInterface from '@/entities/UserInterface';
import QueryResultInterface from '@/entities/QueryResultInterface';
import User from '@/entities/User';
import { HTTPRequestAsync } from '@/infra/HTTPRequest';

@injectable()
export default class implements LoadMyAccountInterface {
  async handleAsync(): Promise<UserInterface> {
    try {
      const session = await Auth.currentSession();
      // #4434      
      const result = await HTTPRequestAsync('get', {
        apiName: 'MTrans.API.Users',
        altAPIName: 'MTrans.API.Users.ALT',
        path: '/v1/my-account?includes-customer=true',
        init: { headers: { "Authorization": session.getIdToken().getJwtToken() }}
      })
      // const result = await API.get('MTrans.API.Users', '/v1/my-account', {
      //   headers: {
      //     "Authorization": session.getIdToken().getJwtToken()
      //   }
      // });
      const queryResult = result as QueryResultInterface;
      const user = new User({
        sub: queryResult.items[0].attributes['sub'].value as unknown as string,
        eMailAddress: queryResult.items[0].attributes['EMailAddress'].value as unknown as string,
        fullName: queryResult.items[0].attributes['Name'].value as unknown as string,
        customerID: queryResult.items[0].attributes['CustomerID'].value as unknown as string,
        language: queryResult.items[0].attributes['Language'].value as unknown as string,
        enabled: queryResult.items[0].attributes['Enabled'].value as unknown as boolean,
        initialSettingsDone: queryResult.items[0].attributes['InitialSettingsDone'].value as unknown as boolean,
        userRoleList: queryResult.items[0].attributes['UserRoleList'].value as unknown as string[],
        loginHistory: queryResult.items[0].attributes['LoginHistory'].value as unknown as string[],
        isSSOUser: queryResult.items[0].attributes['IsSSOUser'].value as unknown as boolean,
        creationDate: queryResult.items[0].attributes['CreationDate'].value as unknown as string,
        creationTime: queryResult.items[0].attributes['CreationTime'].value as unknown as string,      
        customer: queryResult.items[0].attributes['Customer'].value as unknown as { 
          CustomerID: string; 
          CompanyName: string; 
          AppClientIDForIDProvider: string; 
          IsGenerativeAIRejected: string;
          CreationDate?: string;
        }
      })
      return user      
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}